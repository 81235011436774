import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Recaptcha from "react-recaptcha"
import { useMutation } from "@apollo/client"
import { MexicanRegister } from "../crud/createRegister"

// Components
import { SectionTitle, Input } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { toast } from "react-toastify"
import { Seo } from "../components/seo"
import useSSR from "use-ssr"
const RegisterMexico = () => {
  const { isBrowser } = useSSR()
  const [register, setRegister] = useState({
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    PhoneNumber: "0",
    State: "-",
    CitizenshipCountry: "",
    NativeLanguage: "-",
    WorkID: "0",
    WorkConditions: "",
    form_type: 2,
    PrevRegister: "",
    messageError: {},
    isVerified: false,
  })
  const [loading, setLoading] = useState(false)
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const { messageError } = register

  const handleChange = e => {
    const { name, value } = e.target
    setRegister(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = register.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          value !== register.Email
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : ""
        break

      case "PrevRegister":
        errors.PrevRegister =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }

  const [addMxRegister] = useMutation(MexicanRegister, {
    onCompleted: () => {
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterMexican = () => {
    addMxRegister({
      variables: {
        input: {
          data: {
            FullName: register.FullName,
            Email: register.Email,
            PhoneNumber: register.PhoneNumber,
            State: register.State,
            CitizenshipCountry: register.CitizenshipCountry,
            NativeLanguage: register.NativeLanguage,
            WorkID: register.WorkID,
            WorkConditions: register.WorkConditions,
            form_type: register.form_type,
            PrevRegister: register.PrevRegister,
          },
        },
      },
    })
  }
  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setRegister(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (register.isVerified) {
      if (
        register.FullName !== "" &&
        register.Email !== "" &&
        register.PrevRegister !== "" &&
        register.ConfirmEmail !== ""
      ) {
        if (formValidate(register.messageError, register)) {
          setLoading(true)
          handleRegisterMexican()
        } else {
          toast.error("フォームが正しくありません")
        }
      } else {
        toast.error("入力内容を再度ご確認ください。")
      }
    } else {
      recaptchaVerify()
    }
  }

  return (
    <>
      <Seo
        title={`メキシコ在住者お申し込みフォーム | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container contact-us">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="contact-us__responsible-for-company">
              <SectionTitle>メキシコ在住者お申し込みフォーム</SectionTitle>
              <hr className="u-line-bottom" />

              <p>
                弊社サポートの詳細は『
                <Link to="/process">お仕事紹介の流れ</Link>
                』をご覧ください。
              </p>
              <p>
                弊社では出来る限りご登録者様のご希望に沿えるよう、必要に応じて募集企業へ雇用条件の相談なども行っております。※ご希望によってはご相談を承り兼ねる場合がございます。
              </p>
              <form
                className="contact-us__form-contact"
                onSubmit={handleSubmit}
              >
                <div className="form-responsive">
                  <Input>
                    <label htmlFor="氏名（フルネーム）">
                      <p className="form-label"> 氏名（フルネーム） </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="氏名（フルネーム） "
                      name="FullName"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.FullName && (
                      <span className="field-validation">
                        {messageError.FullName}
                      </span>
                    )}
                  </Input>

                  <Input>
                    <label htmlFor="メールアドレス">
                      <p className="form-label">メールアドレス </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="メールアドレス"
                      name="Email"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.Email && (
                      <span className="field-validation">
                        {messageError.Email}
                      </span>
                    )}
                  </Input>
                  <Input>
                    <label htmlFor="メールアドレスの再入力">
                      <p className="form-label">メールアドレスの再入力 </p>
                      <span>必須</span>
                    </label>
                    <input
                      type="text"
                      id="メールアドレスの再入力"
                      name="ConfirmEmail"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                    {messageError.ConfirmEmail && (
                      <span className="field-validation">
                        {messageError.ConfirmEmail}
                      </span>
                    )}
                  </Input>
                  <Input>
                    <label htmlFor="電話番号">
                      <p className="form-label">電話番号</p>
                    </label>
                    <input
                      type="text"
                      id="電話番号"
                      name="PhoneNumber"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="お住まいの州">
                      <p className="form-label">お住まいの州</p>
                    </label>
                    <select
                      id="お住まいの州"
                      name="State"
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <option value="-">-</option>
                      <option value="メキシコ連邦区（メキシコシティ）">
                        メキシコ連邦区（メキシコシティ）
                      </option>
                      <option value="アバソロ (グアナファト州)">
                        アバソロ (グアナファト州)
                      </option>
                      <option value="イラプアト(グアナファト州)">
                        イラプアト(グアナファト州)
                      </option>
                      <option value="サラマンカ(グアナファト州)">
                        サラマンカ(グアナファト州)
                      </option>
                      <option value="シラオ(グアナファト州)">
                        シラオ(グアナファト州)
                      </option>
                      <option value="セラヤ(グアナファト州)">
                        セラヤ(グアナファト州)
                      </option>
                      <option value="レオン(グアナファト州)">
                        レオン(グアナファト州)
                      </option>
                      <option value="その他 グアナファト州">
                        その他 グアナファト州
                      </option>
                      <option value="アグアスカリエンテス州">
                        アグアスカリエンテス州
                      </option>
                      <option value="ケレタロ州">ケレタロ州</option>
                      <option value="サン・ルイス・ポトシ州">
                        サン・ルイス・ポトシ州
                      </option>
                      <option value="グアダラハラ(ハリスコ州)">
                        グアダラハラ(ハリスコ州)
                      </option>
                      <option value="ラゴスデモレノ(ハリスコ州)">
                        ラゴスデモレノ(ハリスコ州)
                      </option>
                      <option value="その他ハリスコ州">その他ハリスコ州</option>
                      <option value="モンテレイ(ヌエボレオン州)">
                        モンテレイ(ヌエボレオン州)
                      </option>
                      <option value="その他ヌエボレオン州">
                        その他ヌエボレオン州
                      </option>
                      <option value="サカテカス州">サカテカス州</option>
                      <option value="その他の地域">その他の地域</option>
                    </select>
                  </Input>
                  <Input>
                    <label htmlFor="国籍">
                      <p className="form-label">国籍</p>
                    </label>
                    <input
                      type="text"
                      id="国籍"
                      name="CitizenshipCountry"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="母国語">
                      <p className="form-label">母国語</p>
                    </label>
                    onBlur={handleChange}
                    <select
                      id=""
                      name="NativeLanguage"
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <option value="-">-</option>
                      <option value="日本語">日本語</option>
                      <option value="スペイン語">スペイン語</option>
                      <option value="英語">英語</option>
                      <option value="その他言語">その他言語</option>
                    </select>
                  </Input>
                  <Input>
                    <label htmlFor="希望する求人番号">
                      <p className="form-label">希望する求人番号</p>
                    </label>
                    <input
                      type="text"
                      id="希望する求人番号"
                      placeholder="例：1000、1001"
                      name="WorkID"
                      onChange={handleChange}
                    />
                  </Input>

                  <Input>
                    <label htmlFor="WorkConditions">
                      <p className="form-label">希望条件、その他 ご質問等</p>
                    </label>
                    <textarea
                      id="area"
                      name="WorkConditions"
                      onChange={handleChange}
                    />
                  </Input>
                  <Input>
                    <label htmlFor="PrevRegister">
                      <p className="form-label">以前にも登録したことがある </p>
                      <span>必須</span>
                    </label>
                    <div className="input-checkbox">
                      <div className="input-checkbox__check-box">
                        <input
                          type="radio"
                          id="yes"
                          value="YES"
                          name="PrevRegister"
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                        <label htmlFor="yes">
                          <p className="form-label">はい</p>
                        </label>
                      </div>
                      <div className="input-checkbox__check-box">
                        <input
                          type="radio"
                          id="no"
                          value="NO"
                          name="PrevRegister"
                          onChange={handleChange}
                          onBlur={handleChange}
                        />
                        <label htmlFor="no">
                          <p className="form-label">いいえ</p>
                        </label>
                      </div>
                      {messageError.PrevRegister && (
                        <span className="field-validation">
                          {messageError.PrevRegister}
                        </span>
                      )}
                    </div>
                  </Input>
                  <div className="captcha-style">
                    {isBrowser && (
                      <Recaptcha
                        render="explicit"
                        sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
                        name="isVerify"
                        verifyCallback={recaptchaVerify}
                      />
                    )}
                    {captchaVerify ? (
                      <span className="field-validation-captcha">
                        Please verify that you are not a robot
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <p>
                    当社の
                    <a
                      className="btn-terms-condition"
                      href="/termsofuse/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      利用規約・個人情報保護方針
                    </a>
                    に同意の上、お申込みください。
                  </p>
                  <div className="u-text-center">
                    {loading === false ? (
                      <input
                        type="submit"
                        value="申し込む"
                        className="btn-submit"
                      />
                    ) : (
                      <p className="buttonload">
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                        &nbsp;&nbsp;少々お待ちください
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default RegisterMexico
